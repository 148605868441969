<template>
    <v-dialog v-model="show" max-width="1200px">
        <v-card>
            <v-card-title>
                <h2 class="subtitle-1">Improve Content</h2>
                <v-spacer></v-spacer>
                <v-btn icon title="close" class="btn-background" @click="show = false">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-card-title>
            <v-card-text v-if="loading" class="d-flex text-center align-center flex-column pt-6">
                <v-progress-circular :size="50" color="primary" indeterminate style="top: 45%"
                    class="mb-3"></v-progress-circular>
                <p>
                    <span v-html="config.contentGenerationConfig.improvementsDisclaimer"></span>
                </p>
            </v-card-text>
            <v-card-text v-else class="pb-4">
                <v-row v-if="remainingIssuesWarning && suggestedTextStatus && suggestedTextStatus.errors.length + suggestedTextStatus.warnings.length > 0">
                    <v-col>
                        <v-alert class="mb-0" text type="warning"><span v-html="remainingIssuesWarning"></span></v-alert>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="6" v-if="textStatus">
                        <h3>Original Text</h3>
                        <div v-html="textStatus.highlightedText"></div>
                        <h3 class="mt-2" v-if="textStatus.errors.length || textStatus.warnings.length">Issues</h3>
                        <div v-for="(e, i) in textStatus.errors" :key="'error' + i">
                            <v-icon color="error" style="font-size: 18px">mdi-alert</v-icon>
                            {{ e.category }} - <span v-html="e.description"></span>
                        </div>
                        <div v-for="(e, i) in textStatus.warnings" :key="'warn' + i">
                            <v-icon color="warning" style="font-size: 18px">mdi-alert</v-icon>
                            {{ e.category }} - <span v-html="e.description"></span>
                        </div>
                    </v-col>
                    <v-col cols="6" v-if="suggestedTextStatus">
                        <h3>Suggested Text</h3>
                        <div v-html="suggestedTextStatus.highlightedText"></div>
                        <h3 class="mt-2"
                            v-if="suggestedTextStatus.errors.length || suggestedTextStatus.warnings.length">Issues</h3>
                        <div v-for="(e, i) in suggestedTextStatus.errors" :key="'error' + i">
                            <v-icon color="error" style="font-size: 18px">mdi-alert</v-icon>
                            {{ e.category }} - <span v-html="e.description"></span>
                        </div>
                        <div v-for="(e, i) in suggestedTextStatus.warnings" :key="'warn' + i">
                            <v-icon color="warning" style="font-size: 18px">mdi-alert</v-icon>
                            {{ e.category }} - <span v-html="e.description"></span>
                        </div>

                        <h3>Refine with additional instructions
                        <v-btn icon @click="additionalInstructions.push('')" title="Add instruction"><v-icon>mdi-plus-circle-outline</v-icon></v-btn></h3>

                        <v-row v-for="(ins, insi) in additionalInstructions" :key="'inst' + insi" class="mx-1">
                            <v-text-field placeholder="Add instruction to AI"
                                v-model="additionalInstructions[insi]"
                                hide-details>
                            </v-text-field>
                        </v-row>
                    </v-col>
                </v-row>
                <v-row class="mb-4" v-if="responseData && responseData.promptMessages">
                    Attempts: {{ responseData.attempts }}
                    <v-expansion-panels>
                        <v-expansion-panel v-for="(m, mi) in responseData.promptMessages" :key="'message' + mi">
                            <v-expansion-panel-header class="px-4"><b>{{ m.role }} prompt</b></v-expansion-panel-header>
                            <v-expansion-panel-content>
                                <span v-html="m.content.replaceAll('\n', '<br />')"></span>
                            </v-expansion-panel-content>
                        </v-expansion-panel>
                    </v-expansion-panels>
                </v-row>
            </v-card-text>
            <v-card-actions class="px-6 pb-4">
                <v-btn outlined color="primary" @click="show = false">Cancel</v-btn>
                <v-spacer></v-spacer>
                <v-btn outlined color="primary" class="ml-2" @click="improveContent">Retry</v-btn>
                <v-btn color="primary" class="ml-2" @click="acceptSuggestion">Accept Suggestion</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import viewUtils from './utils';
import utils from "@/common/utils.js";
import { mapState } from "vuex";

export default {
    name: 'AIImprove',
    props: {
        data: Object
    },
    data: function () {
        return {
            loading: true,
            documentLanguage: { text: "English", value: "en", flagCode: "gb" },
            show: false,
            textStatus: null,
            responseData: null,
            suggestedTextStatus: null,
            additionalInstructions: []
        }
    },
    components: {
    },
    watch: {
        data(newVal) {
            if (newVal) {
                this.init(newVal);
            }
        },
        show(val) {
            if (!val) {
                this.textStatus = null;
                this.response = null;
                this.suggestedTextStatus = null;
                this.additionalInstructions.splice(0);
                this.$emit("close");
            }
        }
    },
    mounted() {

    },
    created() {
        if (this.data) {
            this.init(this.data);
        }
    },
    computed: {
        ...mapState({
            config: (state) => state.settings.config
        }),
        remainingIssuesWarning() {
            if (this.config.contentGenerationConfig.remainingIssuesWarning !== undefined)
                return this.config.contentGenerationConfig.remainingIssuesWarning;
            else
                return "Unfortunately we were unable to remove all the issues whilst keeping the same meaning.";
        }
    },
    methods: {
        acceptSuggestion() {
            this.$emit("setValue", this.responseData.content, this.responseData.request_id);
            this.show = false;
        },
        init() {
            this.textStatus = utils.applyHighlights(this.data.text, this.data.partType.wordChecks, true, false);
            this.improveContent();
        },
        async improveContent() {
            this.responseData = null;

            this.loading = true;
            this.show = true;

            const data = {
                contentType: 'improve',
                contentTpId: this.data.partType.tmpl_part_id,
                language: this.data.docLanguage?.text ?? "English",
                spelling: this.data.spelling,
                docId: this.data.docId,
                text: this.data.text,
                additionalInstructions: this.additionalInstructions.filter(i => i)
            }
            let result = await viewUtils.generateExternalContent(data);
            this.loading = false;
            this.responseData = result.data.Data;
            this.attempts = result.data.Data?.attempts;

            this.suggestedTextStatus = utils.applyHighlights(this.responseData.content, this.data.partType.wordChecks, true, false);
        },
    }
}
</script>
<style scoped lang="scss">
.regenBtn {
    position: fixed;
    z-index: 1;
    top: 0;
    right: 0;
}
</style>